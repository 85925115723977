import { Component } from '@angular/core';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-pitchin',
  templateUrl: './about-pitchin.component.html',
  styleUrls: ['./about-pitchin.component.scss'],
  providers: [
    {
      provide: NgbCarouselConfig,
      useValue: {
        interval: 0,
        showNavigationArrows: true,
      },
    },
  ],
})
export class AboutPitchinComponent {
  activeSelection: any;
  partners = [
    { imgId: '1', logoUrl: 'assets/img/brand/logo_mdec@2x.png' },
    { imgId: '2', logoUrl: 'assets/img/brand/logo_cradle@2x.png' },
    { imgId: '3', logoUrl: 'assets/img/brand/logo_mban@2x.png' },
    { imgId: '4', logoUrl: 'assets/img/brand/logo_cyberview@2x.png' },
    { imgId: '5', logoUrl: 'assets/img/brand/logo_magic@2x.png' },
    { imgId: '6', logoUrl: 'assets/img/brand/logo_mtdc@2x.png' },
    { imgId: '7', logoUrl: 'assets/img/brand/logo_mansec@2x.png' },
    { imgId: '8', logoUrl: 'assets/img/brand/logo_mahwengkwai@2x.png' },
    { imgId: '9', logoUrl: 'assets/img/brand/logo_limjoyan@2x.png' },
    { imgId: '10', logoUrl: 'assets/img/brand/logo_startuptoolkit@2x.png' },
    { imgId: '11', logoUrl: 'assets/img/brand/logo_workana@2x.png' },
  ];

  team = [
    {
      name: 'Sam Shafie',
      position: 'CEO and Co-Founder',
      imageUrl: 'assets/img/team/Sam_Shafie.png',
      bioDetail:
        'Sam co-founded pitchIN and is legally trained. He has served as a Deputy Public Prosecutor in the Attorney-General Chambers of Malaysia \
        as well as in the Securities Commission of Malaysia (SC) and the Malaysian Communications and Multimedia Commission (MCMC). \
        Sam is also the Founder of WatchTower and Friends (WTF), a tech startup accelerator. \
        He has been named by Digital News Asia in their 2016-2017 Digerati 50.',
    },
    {
      name: 'Kashminder Singh',
      position: 'CSO and Co-Founder',
      imageUrl: 'assets/img/team/Kashminder_Singh.png',
      bioDetail:
        'Kash is the co-founder and Chief Strategy Officer at pitchIN. He is also the co-founder of WatchTower and Friends, a startups accelerator. \
        A life-long entrepreneur, he has built multiple successful businesses. \
        Kash was previously the founder and Managing Editor of MOBILE WORLD, a leading tech publication that ran for 11 years. \
        He also co-created GoMobile, a groundbreaking event that incorporated a large consumer expo, conference and an awards night. \
        He is passionate about entrepreneur development and loves working on innovative financing options for startups and small businesses.',
    },
    {
      name: 'Xelia Tong',
      position: 'COO',
      imageUrl: 'assets/img/team/Xelia_Tong.png',
      bioDetail:
        "Xelia is a key player in Malaysia's funding ecosystem, with a career spanning project financing, venture capital, \
        and start-up acceleration. At Malaysia Debt Ventures (MDV), she facilitated the establishment of a world-class games development center. \
        She later managed a RM 200m Creative Industry Fund at Bank Simpanan Nasional (BSN) and, as Vice President of Investment at Cradle Fund, \
        supported 185 start-ups, helping them secure close to RM 90m in follow-on funding. Currently, Xelia is a Venture Partner at ScaleUp Malaysia, \
        serves as an EXCO member of Malaysia Business Angel Network (MBAN) and is an Industry Partner to MSW Ventures, Singapore.",
    },
    {
      name: 'Nicholas Chong',
      position: 'Vise President, Commercial and Product',
      imageUrl: 'assets/img/team/Nicholas_Chong.png',
      bioDetail: '',
    },
    {
      name: 'Lai Kai Bin',
      position: 'Head of Equity Crowdfunding (ECF)',
      imageUrl: 'assets/img/team/Lai_Kai_Bin.png',
      bioDetail: '',
    },
    {
      name: 'Leesa Jaib',
      position: 'Head of Marketing',
      imageUrl: 'assets/img/team/Leesa_Jaib.png',
      bioDetail: '',
    },
    {
      name: 'Faten Nabila',
      position: 'Head of Compliance',
      imageUrl: 'assets/img/team/Faten_Nabila.png',
      bioDetail:
        'Faten was admitted to the Malaysian Bar in 2013 as Advocate and Solicitor of Malaya. \
        She has more than 10 years of experience working as in-house counsel in a variety of sectors, including the financial sector, \
        solid waste management, education, and public transportation.',
    },
    {
      name: 'Azah Azizan',
      position: 'Head of Legal and Corporate Services',
      imageUrl: 'assets/img/team/Azah_Azizan.png',
      bioDetail: '',
    },
    {
      name: 'Tony Thian',
      position: 'Head of Technology',
      imageUrl: 'assets/img/team/Tony_Thian.png',
      bioDetail: '',
    },
  ];

  constructor(private modalService: NgbModal) {}

  openModal(content: any, index: number) {
    this.activeSelection = index + 1;
    const modalRef = this.modalService.open(content, {
      size: '80',
      centered: true,
      windowClass: 'window-modal',
    });
    modalRef.result.then(
      (result) => {},
      (reason) => (this.activeSelection = null),
    );
  }
}
