import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CookieService } from './cookie.service';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CokeepsService {
  private readonly window;
  private readonly hostSignPublicKey: string;

  public CKSDK = null;

  constructor(
    private readonly cookieService: CookieService,
    private readonly authService: AuthService,
  ) {
    this.CKSDK = (window as any).CKSDK;

    this.hostSignPublicKey = environment.cokeeps.publicKey;

    const params = {
      hostSignPublicKey: this.hostSignPublicKey,
      hostId: 'cokeeps-hotwallet',
      hostEndpoint: environment.cokeeps.hostEndpoint,
    };

    if (this.CKSDK) {
      this.CKSDK.auth.setup(params);
      console.log('SDK auth - Loaded');
    }
  }

  async restoreSession() {
    console.log('SDK auth - Starting session restoration process');

    if (this.authService.isAuthenticated()) {
      console.log('SDK auth - User is authenticated, verifying session secret');

      try {
        const secretToken = this.retrieveSessionSecret();

        // Here will check whether the session secret has expired (> 1 day) and
        // the secret belong to the actual owner
        const authResult = await this.authService.verifySessionSecret({
          secretToken: secretToken,
        });

        if (!authResult) {
          console.error('SDK auth - Session secret verification failed');
          return;
        }

        console.log('SDK auth - Session secret verified, proceeding to load authentication');

        try {
          if (await this.CKSDK.auth.load(this.hostSignPublicKey, secretToken)) {
            console.log('SDK auth - Authentication load successful, checking connection status');

            if (await this.CKSDK.auth.connected()) {
              console.log('SDK auth - Session restored successfully');
            } else {
              throw new Error('SDK auth - Failed to restore login session, connection issue');
            }
          } else {
            throw new Error('SDK auth - Load method did not succeed');
          }
        } catch (error) {
          console.error('SDK auth - Error during session restoration:', error);
        }
      } catch (error) {
        console.error('SDK auth - Error during session secret verification:', error);
      }
    } else {
      console.log('SDK auth - User is not authenticated, skipping session restoration');
    }
  }

  async login(email: string, password: string) {
    console.log('SDK auth - Attempt to login');

    await this.CKSDK.auth.login({
      username: email,
      password: password,
    });

    this.setSessionSecret();
  }

  async logOut() {
    await this.CKSDK.auth.logout();
  }

  retrieveSessionSecret() {
    const sessionSecret = this.cookieService.getCookie('X-PitchIN-Session-Secret-Token');

    try {
      const sessionSecretData = this.parseJwt(sessionSecret);

      // Check if the token is expired
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

      if (sessionSecretData.exp && sessionSecretData.exp < currentTime) {
        throw new Error('SDK auth - Session secret has expired');
      }

      return sessionSecret;
    } catch {
      throw new Error('SDK auth - Failed to retrieve session secret');
    }
  }

  setSessionSecret() {
    const secret = this.retrieveSessionSecret();

    this.CKSDK.auth.store(secret);

    console.log('SDK auth - Logged In. Secret set successfully');
  }

  verifyAuth() {
    this.CKSDK.auth.connected();
    console.log('SDK auth - Connected');
  }

  // Credit to: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
  parseJwt(token) {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  }
}
