<article class="tw-flex tw-flex-wrap tw-gap-2">
  <p
    *ngFor="let tag of tags"
    class="tw-m-0 tw-rounded-md tw-border tw-border-gray-400 tw-px-2 tw-py-1 tw-font-body tw-text-sm tw-font-normal tw-text-gray-400"
    [class]="tag"
  >
    {{ 'campaign.' + tag | translate }}
  </p>
  <p class="tw-m-0 tw-font-bold" *ngIf="!tags.length">-</p>
</article>
