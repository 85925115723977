<div class="row" *ngIf="isUpdate">
  <div class="col-lg-12 mb-4 text-justify">{{ 'company.businessInfoDesc' | translate }}</div>
</div>

<ng-container *ngIf="isPreloading">
  <agmo-loading></agmo-loading>
</ng-container>

<form
  (ngSubmit)="submit()"
  [formGroup]="form"
  [class.show-form-error]="showFormError"
  *ngIf="form && ckEditorLoaded && !isPreloading"
>
  <div class="row">
    <div class="col-lg-12 input-panel">
      <div class="input-field" *ngIf="!isLoading">
        <div
          class="profile-upload square-upload"
          [class.m-0]="isUpdate"
          agmoUploader
          accept="image/*"
          (selectFiles)="selectFile($event, 1)"
        >
          <div class="profile-image pitchin-icon-company-logo" *ngIf="!logoUrl"></div>
          <img class="profile-image" alt="Company" [src]="logoUrl" *ngIf="logoUrl" />
          <div class="pitchin-icon pitchin-icon-add" *ngIf="logoUrl"></div>
        </div>
      </div>
      <div class="text-primary pt-3" [class.text-center]="!isUpdate" *ngIf="!logoUrl">
        {{ 'company.squareLogoInstruction' | translate }}
      </div>
      <agmo-loading *ngIf="isLoading"></agmo-loading>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 input-panel">
      <div class="input-label">
        {{ (isUpdate ? 'company.registeredName' : 'company.name') | translate }}*
      </div>
      <div class="input-field">
        <input class="form-control" formControlName="name" type="text" />
      </div>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">{{ 'company.businessRegistrationNumber' | translate }}*</div>
      <div class="input-field">
        <input
          class="form-control"
          formControlName="registrationNumber"
          type="text"
          maxlength="20"
          alphanumeric
        />
      </div>
    </div>
    <div class="col-lg-6 input-panel" [hidden]="!isIssuer">
      <div class="input-label">
        {{ 'company.brandName' | translate }}*
        <button
          type="button"
          #tooltip="matTooltip"
          (click)="tooltip.toggle()"
          class="btn-transparent"
          matTooltip="{{ 'company.brandNameTooltips' | translate }}"
          matTooltipClass="tooltip-inner"
        >
          <i class="fa fa-info-circle"></i>
        </button>
      </div>
      <div class="input-field">
        <input class="form-control" formControlName="brandName" type="text" maxlength="50" />
      </div>
      <div class="input-desc">
        ({{ 'company.optimalCharacterLength' | translate: { noOfCharacters: 50 } }})
      </div>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">{{ 'company.incorporatedSince' | translate }}*</div>
      <div class="input-field date-input">
        <input
          class="form-control"
          [matDatepicker]="incorporatedDate"
          [min]="minDate"
          [max]="todayDate"
          formControlName="incorporatedDate"
          name="incorporatedDate"
          placeholder="DD/MM/YYYY"
        />
        <mat-datepicker-toggle matSuffix [for]="incorporatedDate" disableRipple>
          <div class="pitchin-icon pitchin-icon-calendar" matDatepickerToggleIcon></div>
        </mat-datepicker-toggle>
        <mat-datepicker #incorporatedDate></mat-datepicker>
      </div>
      <div class="error" *ngIf="form.controls.incorporatedDate.errors && showFormError">
        {{ 'common.incorrectMinYear' | translate: { 'year': 1700 } }}
      </div>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">{{ 'company.whatTypeOfBusiness' | translate }}*</div>
      <mat-radio-group
        formControlName="companyType"
        (change)="changeValidity('companyType', 'otherCompanyType', CorporateBusinessType.Other)"
      >
        <mat-radio-button class="d-block" *ngFor="let type of corporateBusinessType" [value]="type">
          <div class="d-flex align-items-center">
            {{ 'company.' + CorporateBusinessType[type] | translate }}
            <input
              *ngIf="
                type == CorporateBusinessType.Other &&
                form.value.companyType == CorporateBusinessType.Other
              "
              class="form-control ml-3"
              formControlName="otherCompanyType"
              type="text"
              placeholder="{{ 'common.pleaseProvide' | translate }}"
            />
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">{{ 'common.tags' | translate }}</div>
      <app-issuer-campaign-tags [campaign]="company?.latestCampaign" />
    </div>
    <div class="col-lg-6 input-panel" [class.col-lg-12]="!isIssuer">
      <div class="input-label">{{ 'company.tagline' | translate }}</div>
      <div class="input-field">
        <textarea
          *ngIf="isIssuer"
          class="form-control"
          [placeholder]="'company.taglinePlaceholder' | translate"
          rows="3"
          formControlName="tagline"
          (change)="checkNoOfWords()"
        ></textarea>
        <input
          *ngIf="!isIssuer"
          (change)="checkNoOfWords()"
          class="form-control"
          formControlName="tagline"
          [placeholder]="'company.taglinePlaceholder' | translate"
          type="text"
        />
      </div>
      <div class="input-desc">({{ 'company.wordsLimit' | translate: { noOfWords: 15 } }})</div>
    </div>
    <div class="col-lg-6 input-panel" *ngIf="isIssuer">
      <div class="input-label">
        {{ (isIssuer ? 'company.businessSummary' : 'company.summary') | translate }}*
      </div>
      <div class="input-field">
        <textarea
          class="form-control"
          rows="3"
          formControlName="description"
          maxlength="90"
          [placeholder]="'company.summaryPlaceholder' | translate"
        ></textarea>
      </div>
      <div class="input-desc">
        ({{ 'company.optimalCharacterLength' | translate: { noOfCharacters: 90 } }})
      </div>
    </div>
    <div class="col-lg-12 input-panel" *ngIf="isIssuer">
      <div class="input-label">{{ 'company.about' | translate }}</div>
      <div class="input-field">
        <ckeditor
          class="ck-editor"
          formControlName="info"
          (focus)="removeRichText($event)"
          [editor]="Editor"
          [config]="ckEditorConfig"
        ></ckeditor>
      </div>
    </div>
    <ng-container *ngIf="isIssuer">
      <ng-container *ngTemplateOutlet="addressForm"></ng-container>
    </ng-container>

    <div class="col-lg-12 input-panel" *ngIf="!isIssuer">
      <fieldset class="inputGroup">
        <legend>
          {{
            (isUpdate ? 'company.registeredAddressUpdate' : 'company.registeredAddress') | translate
          }}
        </legend>
        <div class="row">
          <ng-container *ngTemplateOutlet="addressForm"></ng-container>
        </div>
      </fieldset>
    </div>
    <div class="col-lg-12 input-panel" *ngIf="!isIssuer">
      <fieldset class="inputGroup">
        <legend>
          {{ (isUpdate ? 'company.businessAddress' : 'company.businessLLPAddress') | translate }}
        </legend>
        <div class="row">
          <div class="col-lg-12 input-panel" *ngIf="!isIssuer">
            <mat-checkbox formControlName="sameAsRegisteredAddress" (change)="copyAddress()">
              {{ 'company.sameAsRegisteredAddress' | translate }}
            </mat-checkbox>
          </div>
          <div class="col-lg-12 input-panel">
            <div class="input-label">{{ 'company.address' | translate }}*</div>
            <div class="input-field">
              <textarea class="form-control" rows="3" formControlName="businessAddress"></textarea>
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-label">{{ 'company.postcode' | translate }}*</div>
            <div class="input-field">
              <input
                class="form-control"
                formControlName="businessPostCode"
                type="text"
                alphanumeric
                maxlength="20"
              />
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-label">{{ 'company.city' | translate }}*</div>
            <div class="input-field">
              <input class="form-control" formControlName="businessCity" type="text" />
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-label">{{ 'raise.country' | translate }}*</div>
            <div class="input-field">
              <mat-select
                [placeholder]="'common.selectPlaceholder' | translate"
                class="form-control"
                formControlName="businessCountryId"
                (selectionChange)="getStates(form.value.businessCountryId, 'businessStateId')"
              >
                <mat-option *ngFor="let country of countries" [value]="country.isoCode">
                  {{ country.name }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="col-lg-6 input-panel">
            <div class="input-label">
              {{ 'company.state' | translate
              }}<span *ngIf="businessStates && businessStates.length > 0">*</span>
            </div>
            <div class="input-field">
              <mat-select
                [placeholder]="'common.selectPlaceholder' | translate"
                class="form-control"
                formControlName="businessStateId"
              >
                <mat-option *ngFor="let state of businessStates" [value]="state.isoCode">
                  {{ state.name }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">{{ 'company.phoneNo' | translate }}*</div>
      <div class="input-field phone-input-field">
        <mat-select class="form-control countryCode" formControlName="phoneCountryCode">
          <mat-select-trigger>
            {{ form.value.phoneCountryCode }}
          </mat-select-trigger>
          <mat-option *ngFor="let country of countries" [value]="country.phonecode">
            {{ country.name }} ({{ country.phonecode }})
          </mat-option>
        </mat-select>
        <input class="form-control" formControlName="phoneNumber" type="text" digitOnly />
      </div>
      <div
        class="error"
        *ngIf="
          form.controls.phoneNumber.errors &&
          form.controls.phoneNumber.errors.phoneInvalidFormat &&
          showFormError
        "
      >
        {{ 'common.incorrectPhoneNoFormat' | translate }}
      </div>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">{{ 'company.companyEmail' | translate }}*</div>
      <div class="input-field">
        <input class="form-control" formControlName="email" type="text" maxlength="50" />
      </div>
      <div
        class="error"
        *ngIf="showFormError && form.controls.email.errors && form.controls.email.errors.pattern"
      >
        {{ 'signUp.emailFormatIncorrect' | translate }}
      </div>
    </div>
    <div class="col-lg-12 input-panel">
      <div class="input-label">{{ 'company.sectors' | translate }}*</div>
      <div class="input-field">
        <app-select-search-filter
          class="w-100"
          [(list)]="sectors"
          [(selectedList)]="selectedSectors"
          [placeholder]="'company.searchSectors'"
          (goSearch)="getSectors($event)"
        >
        </app-select-search-filter>
      </div>
      <div class="error" *ngIf="showFormError && selectedSectors.length == 0">
        {{ 'company.sectorIsRequired' | translate }}
      </div>
    </div>
    <div class="col-lg-12 input-panel" [class.form-group]="!msicCodes || msicCodes.length == 0">
      <div class="row">
        <div class="col-lg-6">
          <div class="input-label">{{ 'company.msicCode' | translate }}</div>
          <div class="input-field d-flex">
            <input
              type="text"
              class="form-control w-75 mr-3"
              [(ngModel)]="msicCode"
              alphanumeric
              [ngModelOptions]="{ standalone: true }"
            />
            <button
              type="button"
              class="btn btn-primary btn-block btn-add w-25"
              (click)="addCode()"
            >
              {{ 'common.add' | translate }}
            </button>
          </div>
        </div>
        <div class="col-lg-12" *ngIf="msicCodes && msicCodes.length > 0">
          <div class="block-badge-wrap" *ngFor="let code of msicCodes">
            <div class="d-flex flex-row block-badge mr-3">
              <div class="font-weight-bold mr-2" [matTooltip]="code">
                {{ code.length > 20 ? code.slice(0, 20) + '...' : code }}
              </div>
              <div class="mr-2">|</div>
              <div class="align-self-center cursor-pointer" (click)="deleteCode(code)">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">{{ 'company.website' | translate }}</div>
      <div class="input-field">
        <input class="form-control" formControlName="websiteUrl" type="text" />
      </div>
      <div class="text-danger" *ngIf="showFormError && form.controls.websiteUrl.errors?.pattern">
        {{ 'common.invalidUrl' | translate }}
      </div>
    </div>
    <div class="col-lg-6 input-panel" *ngIf="isIssuer">
      <div class="input-label">{{ 'company.reel' | translate }}</div>
      <div class="input-field">
        <input class="form-control" formControlName="videoUrl" type="text" />
      </div>
      <div class="input-desc">{{ 'company.pasteVideoUrl' | translate }}</div>
    </div>
    <ng-container formArrayName="companySocialLinks">
      <div
        class="col-lg-6 input-panel"
        *ngFor="let socialLink of socialFormControl.controls; let i = index"
        formGroupName="{{ i }}"
      >
        <div class="input-label">
          {{ 'common.' + SocialLinkType[socialLink.value.type] | translate }}
        </div>
        <div class="input-field">
          <input class="form-control" formControlName="url" type="text" />
        </div>
      </div>
    </ng-container>
    <div class="col-lg-7 input-panel" *ngIf="isIssuer">
      <div class="input-label">{{ 'company.banner' | translate }}</div>
      <div class="input-field" *ngIf="!isBannerLoading">
        <div
          class="banner-upload"
          agmoUploader
          [accept]="accept"
          (selectFiles)="selectFile($event, 2)"
          *ngIf="!bannerUrl"
        >
          <div class="upload-container upload-field d-flex">
            <div class="align-self-center px-4">
              <div class="pitchin-icon pitchin-icon-upload"></div>
              <div class="drop-document">
                {{ 'company.uploadImage' | translate }}
              </div>
              <div class="upload-document-instruction">
                {{ 'company.uploadDocumentInstruction' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="banner-upload" *ngIf="bannerUrl">
          <div class="upload-container">
            <img class="banner-image" alt="Company Banner" [src]="bannerUrl" *ngIf="bannerUrl" />
            <div
              class="pitchin-icon pitchin-icon-add"
              (click)="removeBanner()"
              *ngIf="bannerUrl"
            ></div>
          </div>
        </div>
      </div>
      <agmo-loading *ngIf="isBannerLoading"></agmo-loading>
    </div>
    <ng-container *ngIf="!isIssuer">
      <hr class="w-100" />
      <div class="col-lg-6 input-panel">
        <div class="input-label">{{ 'company.grossAnnualRevenue' | translate }}*</div>
        <div class="input-field">
          <mat-select
            [placeholder]="'common.selectPlaceholder' | translate"
            class="form-control"
            formControlName="grossAnnualRevenueType"
          >
            <mat-option *ngFor="let type of grossAnnualRevenue" [value]="type.id">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="col-lg-12 input-panel">
        <div class="input-label">{{ 'company.corporateCategory' | translate }}*</div>
        <div class="input-field">
          <mat-select
            [placeholder]="'common.selectPlaceholder' | translate"
            class="form-control"
            formControlName="corporateCategoryType"
            (selectionChange)="
              changeValidity('corporateCategoryType', 'othersCorporateCategoryType', this.othersId)
            "
          >
            <mat-option *ngFor="let type of corporateCategory" [value]="type.id">
              {{ type.name }}
            </mat-option>
          </mat-select>
          <input
            *ngIf="form.value.corporateCategoryType == othersId"
            class="form-control mt-2"
            formControlName="othersCorporateCategoryType"
            type="text"
            placeholder="{{ 'common.pleaseSpecify' | translate }}"
            maxlength="512"
          />
        </div>
      </div>
    </ng-container>
    <div class="col-12 input-panel">
      <div class="mt-4 d-flex">
        <mat-checkbox formControlName="isCheck" name="isCheck" required> </mat-checkbox>
        <div [innerHTML]="'profile.confirmInfo' | translate"></div>
      </div>
    </div>
  </div>
  <div class="row pt-5">
    <div class="col-lg-6 col-md-8 input-panel" [class.m-auto]="!isUpdate">
      <button type="submit" class="btn btn-primary btn-submit">
        {{ (isUpdate ? 'common.update' : 'common.next') | translate }}
      </button>
    </div>
  </div>

  <ng-template #addressForm>
    <div class="col-lg-12 input-panel">
      <div class="input-label">
        {{ (isIssuer ? 'company.businessAddress' : 'company.address') | translate }}*
      </div>
      <div class="input-field">
        <textarea
          *ngIf="isIssuer"
          class="form-control"
          rows="3"
          formControlName="address"
        ></textarea>
        <input *ngIf="!isIssuer" class="form-control" formControlName="address" type="text" />
      </div>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">{{ 'company.postcode' | translate }}*</div>
      <div class="input-field">
        <input
          class="form-control"
          formControlName="postCode"
          type="text"
          alphanumeric
          maxlength="20"
        />
      </div>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">{{ 'company.city' | translate }}*</div>
      <div class="input-field">
        <input class="form-control" formControlName="city" type="text" />
      </div>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">{{ 'raise.country' | translate }}*</div>
      <div class="input-field">
        <mat-select
          [placeholder]="'common.selectPlaceholder' | translate"
          class="form-control"
          formControlName="countryId"
          (selectionChange)="getStates(form.value.countryId, 'stateId')"
          [disabled]="isIssuer"
        >
          <mat-option *ngFor="let country of countries" [value]="country.isoCode">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="col-lg-6 input-panel">
      <div class="input-label">
        {{ 'company.state' | translate }}<span *ngIf="states && states.length > 0">*</span>
      </div>
      <div class="input-field">
        <mat-select
          [placeholder]="'common.selectPlaceholder' | translate"
          class="form-control"
          formControlName="stateId"
        >
          <mat-option *ngFor="let state of states" [value]="state.isoCode">
            {{ state.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </ng-template>
</form>
