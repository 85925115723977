import { OAuthType } from './../../core/enum/oauth-type.enum';
import { PopupService } from '../../shared/services/popup/popup.service';
import { LoginModel } from './../../core/models/account/login.model';
import { DeviceModel } from './../../core/models/account/device.model';
import { ErrorService } from './../../core/services/error.service';
import { BlockUiService } from './../../shared/services/blockUi/block-ui.service';
import { AccountService } from './../../core/services/api/account.service';
import { StorageService } from './../../core/services/storage.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// import { AngularFireAuth } from '@angular/fire/auth';
// import firebase from 'firebase/app';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '../../core/enum/role.enum';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ForceUpdateMessageComponent } from './force-update-message/force-update-message.component';
import { GtmDataLayerService } from '../../core/services/gtm-data-layer.service';
import { UserProfile } from '../../core/models/user/user-profile';
import { EkycStatus } from '../../core/enum/ekyc-status.enum';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/services/auth/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { WindowReferenceService } from '../../core/services/window-reference.service';
import { CokeepsService } from '../../core/services/cokeeps.service';
import { CookieService } from '../../core/services/cookie.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  step: number = 1;

  showFormError: boolean = false;
  showPassword: boolean;
  form: UntypedFormGroup;

  OAuthType: typeof OAuthType = OAuthType;

  isCokeepsEnabled: boolean = environment.flagToUseCokeeps;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly storageService: StorageService,
    // public afAuth: AngularFireAuth,
    private readonly accountService: AccountService,
    private readonly blockUiService: BlockUiService,
    private readonly errorService: ErrorService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly popupService: PopupService,
    private readonly modalService: NgbModal,
    private readonly translateService: TranslateService,
    private readonly _gtmDataLayerService: GtmDataLayerService,
    private readonly authService: AuthService,
    private readonly _windowRef: WindowReferenceService,
    private readonly cokeepsService: CokeepsService,
    @Inject(PLATFORM_ID) private readonly platform: Object,
  ) {}

  ngOnInit() {
    this.buildForm();

    if (isPlatformBrowser(this.platform) && this.authService.isAuthenticated()) {
      this.router.navigate(['/profile/settings/profile-details'], {
        replaceUrl: true,
      });
      return;
    }

    if (this.storageService.isRememberMe) {
      this.form.controls.isRememberMe.setValue(this.storageService.isRememberMe);
      this.form.controls.email.setValue(this.storageService.email);
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      isRememberMe: [false],
    });
  }

  toggle() {
    this.showPassword = !this.showPassword;
  }

  handleBack() {
    this.step = 1;
  }

  submit(otp?: number) {
    if (this.form.invalid) {
      this.showFormError = true;
      return;
    }

    let body: LoginModel = {
      Email: this.form.value.email.trim(),
      Password: this.form.value.password.trim(),
      Device: new DeviceModel(this.storageService.deviceId, this.storageService.registrationId),
    };

    // Include otp into form when it exist
    if (otp) {
      body = {
        ...body,
        Otp: otp,
      };
    }

    this.blockUiService.open();

    this.accountService
      .login(body)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: (data) => {
          this.storageService.isRememberMe = this.form.value.isRememberMe;
          this.storageService.email =
            this.form.value.isRememberMe == true ? this.form.value.email : null;

          let role = data.role;

          if (!role || role == Role.SuperAdmin || role == Role.Admin) {
            this.popupService.alert('common.unauthorisedAccess');
            this.accountService.logout().subscribe();
            return;
          }

          if (data.isForceUpdateUserProfile) {
            const popupOptions = {
              size: 'lg',
              centered: true,
              keyboard: false,
              windowClass: 'modal-full-screen',
            } as NgbModalOptions;

            const modalRef = this.modalService.open(ForceUpdateMessageComponent, popupOptions);
            modalRef.componentInstance.name = data.fullName;

            modalRef.result.then(
              () => {
                this.checkSignUpFlow(role, data);
              },
              () => {
                this.checkSignUpFlow(role, data);
              },
            );
          } else {
            this.checkSignUpFlow(role, data);
          }
        },
        error: (err) => {
          if (err.error && err.error.code == 'ERR_ACCOUNT_NOT_COMFIRMED') {
            this.popupService
              .confirm('signUp.confirmResendEmail', {
                titleLabel: err.error.message,
                okLabel: 'common.confirm',
                cancelLabel: 'common.no',
              })
              .then(
                () => {
                  this.resendEmail();
                },
                () => {},
              );
          } else if (err.error && err.error.code == 'ERR_ENCRYPTION_OUT_OF_DATE') {
            this.popupService
              .alert('signUp.enhancedSecurity', {
                titleLabel: err.error.message,
                okLabel: 'resetPassword.title',
                cancelLabel: '',
                backdrop: true,
                showTitle: false,
              })
              .then(
                () => {
                  this.forceResetPassword(this.form.value.email.trim());
                },
                () => {},
              );
          } else if (err?.code === 'ERR_REQ_2FA_CODE') {
            // Although this returns HTTP code 206, we force this to return as an error so this code path will work.
            this.step = 2;
          } else {
            this.errorService.showError(err, null, true);
          }
        },
      });
  }

  submitWithOtp(otp: number) {
    this.submit(otp);
  }

  forceResetPassword(emailAddress: string) {
    this.blockUiService.open();

    this.accountService
      .forgotPassword(emailAddress)
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: () => {
          this.router.navigate(['/forgot-password'], {
            queryParams: {
              isSent: true,
              email: emailAddress,
            },
          });
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  resendEmail() {
    this.blockUiService.open();

    this.accountService
      .resendVerifyEmail(this.form.value.email.trim())
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: () => {
          this.popupService
            .alert(
              this.translateService.instant('signUp.emailSent', { email: this.form.value.email }),
            )
            .catch((err) => {
              console.error('unable to resend verification email');
            });
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  checkSignUpFlow(role: Role, user?: UserProfile) {
    const { email, password } = this.form.controls;

    this.accountService
      .getSignUpFlowStatus()
      .pipe(finalize(() => this.blockUiService.close()))
      .subscribe({
        next: (flowData) => {
          if (flowData && !flowData.isCompleted) {
            let roleText = Role[role].split(/(?=[A-Z])/);
            let roleRoute = roleText.join('-').toLowerCase();
            this.router.navigate(['/sign-up', roleRoute]);
            this.accountService.currentUser.next(null);
            return;
          }

          this.storageService.role = role;
          this.accountService.profile(true).subscribe({
            next: async () => {
              // Once successful login, log `user_id` to gtm
              this._gtmDataLayerService.logUser(
                user.id,
                role,
                this.accountService.currentUser.value.ekycStatus == EkycStatus.Verified,
                user.hasTransaction,
                user.email,
              );

              // Also handle for cokeeps
              if (this.isCokeepsEnabled) {
                await this.cokeepsService.login(email.value, password.value);
              }
            },
            error: (err) => {
              this.errorService.showError(err);
            },
          });

          const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];

          const urlTree = this.router.parseUrl(returnUrl);
          const path =
            urlTree.root.children.primary?.segments?.map((segment) => segment.path).join('/') ??
            '/';

          const excludedReturnUrls = ['/sign-up', '/login'];
          const { fragment, queryParams } = urlTree;
          const ieoPathname = new URL(environment.ieoBaseUrl).pathname;

          if (
            returnUrl &&
            !returnUrl.includes('http') &&
            !excludedReturnUrls.some((url) => returnUrl.includes(url))
          ) {
            // Will only redirect to /ieo sites externally
            if (returnUrl.includes(ieoPathname)) {
              window.location.href = environment.ieoBaseUrl.replace(ieoPathname, '') + returnUrl;
            } else {
              this.router
                .navigate([path], {
                  queryParams: queryParams || null,
                  fragment: fragment || null,
                })
                .catch((err) => {
                  console.error('fail to navigate to returnUrl:' + err);
                });
            }
          } else {
            // If anyone attempted to redirect to any 3rd party sites, then back to homepage
            this.router.navigate(['/']).catch((err) => {
              console.error('fail to navigate to homepage:' + err);
            });
          }
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  // signInOAuth(oAuthType: OAuthType) {
  //     let provider: firebase.auth.AuthProvider;

  //     switch (oAuthType) {
  //         case OAuthType.Facebook:
  //             provider = new firebase.auth.FacebookAuthProvider();
  //             break;
  //         case OAuthType.Google:
  //             provider = new firebase.auth.GoogleAuthProvider();
  //             break;
  //         case OAuthType.Twitter:
  //             provider = new firebase.auth.TwitterAuthProvider();
  //             break;
  //     }

  //     this.signInWithSocial(provider, oAuthType);
  // }

  // signInWithSocial(provider: firebase.auth.AuthProvider, oAuthType: OAuthType) {
  //     this.blockUiService.open();

  //     this.afAuth.signInWithPopup(provider)
  //         .then((result) => {
  //             let credential = result.credential as firebase.auth.OAuthCredential;

  //             if (oAuthType == OAuthType.Google) {
  //                 result.user.getIdToken().then(idToken => {
  //                     this.loginOAuth(oAuthType, idToken);
  //                 });
  //             }
  //             else {
  //                 this.loginOAuth(oAuthType, credential.accessToken, credential.secret);
  //             }
  //         }).catch((error) => {
  //             this.blockUiService.close();
  //         });
  // }

  // loginOAuth(oAuthType: OAuthType, accessToken: string, secret?: string) {
  //     this.accountService.loginOAuth(accessToken, oAuthType, secret)
  //         .pipe(finalize(() => this.blockUiService.close()))
  //         .subscribe(data => {
  //             let role = data.role;

  //             if (!role || role == Role.SuperAdmin || role == Role.Admin) {
  //                 this.popupService.alert('common.unauthorisedAccess');
  //                 this.accountService.logout().subscribe();
  //                 return;
  //             }

  //             this.checkSignUpFlow(role);
  //         }, err => {
  //             this.errorService.showError(err);
  //         });
  // }
}
