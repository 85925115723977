<div class="container-fluid max-lg:tw-p-0">
  <div class="tw-flex tw-gap-5 max-lg:tw-flex-wrap">
    <div
      class="tw-flex-[0_0_100%] tw-py-10 tw-pt-5 max-lg:tw-pb-5 lg:tw-max-w-[250px] lg:tw-flex-[0_0_250px]"
    >
      <!-- Tabs -->
      <nav
        class="navbar navbar--lite tw-sticky tw-top-20 tw-pt-4 max-lg:tw-hidden"
        aria-label="settings"
      >
        <ul class="navbar__nav navbar__nav--parent tw-flex tw-flex-col tw-gap-4">
          <ng-container *ngFor="let tab of tabs">
            <li *ngIf="!tab.show || tab.show(role)" class="tw-text-black">
              <ng-container *ngTemplateOutlet="link; context: { item: tab }" />

              <ul class="navbar__nav">
                <ng-container *ngFor="let subTab of tab.submenus">
                  <li *ngIf="!subTab.show || subTab.show(role)" class="nav__item">
                    <ng-container *ngTemplateOutlet="link; context: { item: subTab }" />
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
        </ul>
      </nav>

      <div class="tw-px-4 lg:tw-hidden">
        <app-mobile-select-menu
          [icon]="'dashboard'"
          [menu]="tabs"
          [selected]="selectedMenu"
          (valueChanged)="handleMenuChanged($event)"
        >
        </app-mobile-select-menu>
      </div>
    </div>

    <div class="tw-w-full tw-min-w-[0] tw-pb-10 lg:tw-flex-auto lg:tw-py-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #link let-item="item">
  <a
    *ngIf="item.isExternal"
    class="nav__link tw-flex tw-select-none tw-items-center tw-gap-2.5"
    [href]="item.url"
  >
    <ng-container *ngTemplateOutlet="linkContent; context: { item: item }" />
  </a>

  <a
    *ngIf="!item.isExternal"
    class="nav__link tw-flex tw-select-none tw-items-center tw-gap-2.5"
    [routerLink]="item.url"
    [class.active]="selectedMenu === item.name"
    [ngClass]="{ 'hover:!tw-bg-transparent': item?.submenus?.length }"
  >
    <ng-container *ngTemplateOutlet="linkContent; context: { item: item }" />
  </a>
</ng-template>

<ng-template #linkContent let-item="item">
  <p-icon
    *ngIf="item.icon && !item?.submenus?.length"
    [name]="item.icon"
    [iconClass]="'tw-w-[1.2rem] tw-h-[1.2rem] nav__icon'"
  />
  <p
    class="tw-m-0 tw-text-sm tw-font-medium"
    [ngClass]="{
      '!tw-font-normal tw-uppercase tw-text-gray-600': item?.submenus?.length,
    }"
  >
    {{ item.name | translate }}
  </p>
  <div
    *ngIf="item?.submenus?.length"
    class="tw-h-1 tw-flex-1 -tw-translate-x-1 tw-border-t tw-border-t-gray-300"
  ></div>
</ng-template>
