import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { DecimalPipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { initializeApp } from 'firebase/app';
import { NgChartsModule } from 'ng2-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MomentModule } from 'ngx-moment';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SwiperModule } from 'swiper/angular';
import { HammerModule } from '../../node_modules/@angular/platform-browser';
import { environment } from './../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CoreModule } from './core/core.module';
import { AccountService } from './core/services/api/account.service';
import { BusinessDirectoryService } from './core/services/api/business-directory.service';
import { CampaignService } from './core/services/api/campaign.service';
import { CompanyService } from './core/services/api/company.service';
import { HomeService } from './core/services/api/home.service';
import { InvestmentService } from './core/services/api/investment.service';
import { BusinessesService } from './core/services/api/mysec/businesses.service';
import { TeamsService } from './core/services/api/mysec/teams.service';
import { WalletService } from './core/services/api/mysec/wallet.service';
import { NotificationsService } from './core/services/api/notifications';
import { PaymentService } from './core/services/api/payment.service';
import { PortfolioService } from './core/services/api/portfolio.service';
import { PrismicService } from './core/services/api/prismic.service';
import { PushNotificationService } from './core/services/api/push-notification.service';
import { RaiseService } from './core/services/api/raise.service';
import { RssFeedService } from './core/services/api/rss-feed.service';
import { SettingService } from './core/services/api/setting.service';
import { AuthService } from './core/services/auth/auth.service';
import { BlockAtProductionGuard } from './core/services/auth/block-at-production.service';
import { AuthGuard } from './core/services/auth/guard.service';
import { CountriesService } from './core/services/countries.service';
import { CsvService } from './core/services/csv.service';
import { ErrorService } from './core/services/error.service';
import { FileValidationService } from './core/services/file-validation.service';
import { StatusCodeResponseService } from './core/services/status-code-response.service';
import { MysecLayoutComponent } from './layouts/mysec-layout/mysec-layout.component';
import { ProfileComponent } from './layouts/profile/profile.component';
import { SettingsComponent } from './layouts/settings/settings.component';
import { AboutPitchinComponent } from './pages/about-pitchin/about-pitchin.component';
import { CampaignBadgeComponent } from './pages/equity-campaign/campaign-badge/campaign-badge.component';
import { CampaignBusinessInfoComponent } from './pages/equity-campaign/campaign-business-info/campaign-business-info.component';
import { CampaignBusinessOverviewComponent } from './pages/equity-campaign/campaign-business-overview/campaign-business-overview.component';
import { CampaignHeaderComponent } from './pages/equity-campaign/campaign-header/campaign-header.component';
import { CampaignHeadingComponent } from './pages/equity-campaign/campaign-header/campaign-heading/campaign-heading.component';
import { CampaignStatisticsComponent } from './pages/equity-campaign/campaign-header/campaign-statistics/campaign-statistics.component';
import { CampaignVideoComponent } from './pages/equity-campaign/campaign-header/campaign-video/campaign-video.component';
import { CampaignHistoriesComponent } from './pages/equity-campaign/campaign-histories/campaign-histories.component';
import { CampaignPerformanceComponent } from './pages/equity-campaign/campaign-performance/campaign-performance.component';
import { EquityCampaignComponent } from './pages/equity-campaign/equity-campaign.component';
import { EquityCrowdfundingComponent } from './pages/equity-crowdfunding/equity-crowdfunding.component';
import { FeeStructureComponent } from './pages/fee-structure/fee-structure.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { BusinessDirectoryComponent } from './pages/landing/business-directory/business-directory.component';
import { BusinessInfoComponent } from './pages/landing/business-directory/company-details/business-info/business-info.component';
import { BusinessOverviewComponent } from './pages/landing/business-directory/company-details/business-overview/business-overview.component';
import { CompanyDetailsComponent } from './pages/landing/business-directory/company-details/company-details.component';
import { CampaignInfoComponent } from './pages/landing/campaign-details/campaign-info/campaign-info.component';
import { EddFormComponent } from './pages/landing/campaign-details/edd-form/edd-form.component';
import { EventsComponent } from './pages/landing/campaign-details/events/events.component';
import { FaqsComponent } from './pages/landing/campaign-details/faqs/faqs.component';
import { ChoosePaymentComponent } from './pages/landing/campaign-details/invest-now/choose-payment/choose-payment.component';
import { ConfirmInvestComponent } from './pages/landing/campaign-details/invest-now/confirm-invest/confirm-invest.component';
import { FpxPaymentComponent } from './pages/landing/campaign-details/invest-now/fpx-payment/fpx-payment.component';
import { PaymentReceiptComponent } from './pages/landing/campaign-details/invest-now/payment-receipt/payment-receipt.component';
import { InvestNowComponent } from './pages/landing/campaign-details/invest-now/invest-now.component';
import { PaymentProcessComponent } from './pages/landing/campaign-details/invest-now/payment-process/payment-process.component';
import { InvestmentsComponent } from './pages/landing/campaign-details/investments/investments.component';
import { QaListComponent } from './pages/landing/campaign-details/qa-list/qa-list.component';
import { RisksComponent } from './pages/landing/campaign-details/risks/risks.component';
import { CampaignFiltersComponent } from './pages/landing/campaign-section/campaign-filters/campaign-filters.component';
import { CampaignSectionComponent } from './pages/landing/campaign-section/campaign-section.component';
import { LiveCampaignsComponent } from './pages/landing/campaign-status/live-campaigns/live-campaigns.component';
import { SuccessCampaignsComponent } from './pages/landing/campaign-status/success-campaigns/success-campaigns.component';
import { LandingComponent } from './pages/landing/landing.component';
import { BeyondFourComponent } from './pages/landing/partners/beyond-four/beyond-four.component';
import { MrantiComponent } from './pages/landing/partners/mranti/mranti.component';
import { RiskWarningComponent } from './pages/landing/risk-warning/risk-warning.component';
import { LearnArticleFooterComponent } from './pages/learn/learn-article/learn-article-footer/learn-article-footer.component';
import { LearnArticleReadmoreComponent } from './pages/learn/learn-article/learn-article-readmore/learn-article-readmore.component';
import { LearnArticleSubmenuComponent } from './pages/learn/learn-article/learn-article-submenu/learn-article-submenu.component';
import { LearnArticleComponent } from './pages/learn/learn-article/learn-article.component';
import { LearnPreviewComponent } from './pages/learn/learn-preview/learn-preview.component';
import { LearnComponent } from './pages/learn/learn.component';
import { IeoTermsConditionsComponent } from './pages/legal/ieo-terms-conditions/ieo-terms-conditions.component';
import { NomineeAgreementComponent } from './pages/legal/nominee-agreement/nominee-agreement.component';
import { PersonalDataProtectionComponent } from './pages/legal/personal-data-protection/personal-data-protection.component';
import { PrivacyPolicyComponent } from './pages/legal/privacy-policy/privacy-policy.component';
import { ServiceTermsConditionsComponent } from './pages/legal/service-terms-conditions/service-terms-conditions.component';
import { PstxTermsConditionsComponent } from './pages/legal/pstx-terms-conditions/pstx-terms-conditions.component';
import { WebsiteTermsConditionsComponent } from './pages/legal/website-terms-conditions/website-terms-conditions.component';
import { ForceUpdateMessageComponent } from './pages/login/force-update-message/force-update-message.component';
import { LoginComponent } from './pages/login/login.component';
import { CampaignInvestorListComponent } from './pages/my-campaign/campaign-investor-list/campaign-investor-list.component';
import { MyCampaignComponent } from './pages/my-campaign/my-campaign.component';
import { MyPortfolioComponent } from './pages/my-portfolio/my-portfolio.component';
import { MySharesComponent } from './pages/my-portfolio/my-shares/my-shares.component';
import { PortfolioSharePopupComponent } from './pages/my-portfolio/portfolio-share-popup/portfolio-share-popup.component';
import { MysecSettingsComponent } from './pages/mysec/mysec-settings/mysec-settings.component';
import { AddTeamMemberComponent } from './pages/mysec/mysec-settings/team-management/add-team-member/add-team-member.component';
import { TeamManagementComponent } from './pages/mysec/mysec-settings/team-management/team-management.component';
import { TickerCodeComponent } from './pages/mysec/mysec-settings/team-management/ticker-code/ticker-code.component';
import { RayaWithPitchinComponent } from './pages/news/raya-with-pitchin/raya-with-pitchin.component';
import { ApplicationFormComponent } from './pages/raise/application-form/application-form.component';
import { CampaignDraftRejectComponent } from './pages/raise/campaign-draft-reject/campaign-draft-reject.component';
import { CampaignDraftComponent } from './pages/raise/campaign-draft/campaign-draft.component';
import { CampaignFormComponent } from './pages/raise/campaign-form/campaign-form.component';
import { CreateCampaignDetailsComponent } from './pages/raise/create-campaign-details/create-campaign-details.component';
import { MakePaymentComponent } from './pages/raise/make-payment/make-payment.component';
import { RaiseFundReceiptComponent } from './pages/raise/make-payment/raise-fund-receipt/raise-fund-receipt.component';
import { MyApplicationComponent } from './pages/raise/my-application/my-application.component';
import { NewApplicationComponent } from './pages/raise/new-application/new-application.component';
import { OfferCreationComponent } from './pages/raise/offer-creation/offer-creation.component';
import { ProcessListComponent } from './pages/raise/process-list/process-list.component';
import { RaiseComponent } from './pages/raise/raise.component';
import { ShareholdersAndDirectorsComponent } from './pages/raise/shareholders-and-directors/shareholders-and-directors.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SavedOffersComponent } from './pages/saved-offers/saved-offers.component';
import { UploadDocumentComponent } from './pages/signup/documents/upload-document/upload-document.component';
import { EKycInProgressComponent } from './pages/signup/e-kyc-in-progress/e-kyc-in-progress.component';
import { EKycResultComponent } from './pages/signup/e-kyc-result/e-kyc-result.component';
import { IssuerRepresentativeComponent } from './pages/signup/issuer-representative/issuer-representative.component';
import { ProceedEKycComponent } from './pages/signup/proceed-e-kyc/proceed-e-kyc.component';
import { RegisterCorporateComponent } from './pages/signup/register-corporate/register-corporate.component';
import { RegisterEmailComponent } from './pages/signup/register-email/register-email.component';
import { RegisterInvestorComponent } from './pages/signup/register-investor/register-investor.component';
import { RegisterIssuerComponent } from './pages/signup/register-issuer/register-issuer.component';
import { SignupComponent } from './pages/signup/signup.component';
import { TncModalComponent } from './pages/signup/tnc-modal/tnc-modal.component';
import { VerifyEmailComponent } from './pages/signup/verify-email/verify-email.component';
import { WhyInvestComponent } from './pages/why-invest/why-invest.component';
import { httpInterceptorProviders } from './shared/http-interceptors/http-interceptors';
import { translateBrowserLoaderFactory } from './shared/loader/translate-browser.loader.service';
import { NumberSuffixPipe } from './shared/pipes/number-suffix.pipe';
import { SharedModule } from './shared/shared.module';
// import { LoggingService } from './core/services/logging.service';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { AnnouncementBarComponent } from './components/announcement-bar/announcement-bar.component';
import { FULL_FORMATS } from './core/services/constant';
import { CampaignFooterComponent } from './pages/equity-campaign/campaign-footer/campaign-footer.component';
import { CampaignInfoTabComponent } from './pages/equity-campaign/campaign-info-tab/campaign-info-tab.component';
import { CampaignInvestmentDetailsComponent } from './pages/equity-campaign/campaign-investment-details/campaign-investment-details.component';
import { CampaignWhatsNewComponent } from './pages/equity-campaign/campaign-whats-new/campaign-whats-new.component';
import { AcademyComponent } from './pages/landing/academy/academy.component';
import { CampaignNewsComponent } from './pages/landing/campaign-details/campaign-news/campaign-news.component';
import { EcosystemComponent } from './pages/landing/ecosystem/ecosystem.component';
import { LoginBehalfRedirectionComponent } from './pages/login-behalf-redirection/login-behalf-redirection.component';
import { HistoryComponent } from './pages/my-portfolio/history/history.component';
import { StyleGuideComponent } from './pages/style-guide/style-guide.component';
import { ServiceSectionComponent } from './pages/landing/service-section/service-section.component';
import { StatsSectionComponent } from './pages/landing/stats-section/stats-section.component';
import { HowItWorksComponent } from './pages/mysec/how-it-works/how-it-works.component';
import { PartnershipComponent } from './pages/landing/partnership/partnership.component';
import { BuySellTutorialComponent } from './pages/mysec/how-it-works/buy-sell-tutorial/buy-sell-tutorial.component';
import { BuySellTutorialContentComponent } from './pages/mysec/how-it-works/buy-sell-tutorial/buy-sell-tutorial-content/buy-sell-tutorial-content.component';
import { InnerTabComponent } from './pages/mysec/how-it-works/buy-sell-tutorial/buy-sell-tutorial-content/inner-tab/inner-tab.component';
import { InnerTabContentComponent } from './pages/mysec/how-it-works/buy-sell-tutorial/buy-sell-tutorial-content/inner-tab-content/inner-tab-content.component';
import { BrokuComponent } from './pages/landing/partners/broku/broku.component';
import { ForInvestorsComponent } from './pages/landing/for-investors/for-investors.component';
import { ForFoundersComponent } from './pages/landing/for-founders/for-founders.component';
import { SignupLayoutComponent } from './pages/signup/signup-layout/signup-layout.component';
import { DuitnowPaymentComponent } from './pages/landing/campaign-details/invest-now/duitnow-payment/duitnow-payment.component';
import { SharesPopUpComponent } from './pages/my-portfolio/my-shares/shares-pop-up/shares-pop-up.component';
import { ShareholderInformationComponent } from './pages/mysec/mysec-settings/shareholder-information/shareholder-information.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { InvestorDashboardComponent } from './layouts/investor-dashboard/investor-dashboard.component';
import { TCFCampaignService } from './core/services/api/tcf-campaign.service';
import { TcfCampaignCardComponent } from './pages/landing/tcf-campaign/tcf-campaign-card/tcf-campaign-card.component';
import { ApplicationProcessListComponent } from './pages/raise/application-process-list/application-process-list.component';
import { ValuationBasisPopupComponent } from './pages/my-portfolio/valuation-basis-popup/valuation-basis-popup.component';
import { MyAnnouncementsComponent } from './pages/my-announcements/my-announcements.component';
import { MobileSelectMenuComponent } from './components/mobile-select-menu/mobile-select-menu.component';
import { WomenInTechComponent } from './pages/landing/partners/women-in-tech/women-in-tech.component';
import { WatchTowerAndFriendsComponent } from './pages/landing/partners/watch-tower-and-friends/watch-tower-and-friends.component';
import { StatementsReportsComponent } from './pages/mysec/statements-reports/statements-reports.component';
import { StatementsComponent } from './pages/mysec/statements-reports/statements/statements.component';
import { ReportsComponent } from './pages/mysec/statements-reports/reports/reports.component';
import { EnableTwoFactorAuthComponent } from './pages/settings/security/enable-two-factor-auth/enable-two-factor-auth.component';
import { BackupCodesComponent } from './pages/settings/security/backup-codes/backup-codes.component';
import { TwoFactorAuthInputComponent } from './components/two-factor-auth-input/two-factor-auth-input.component';
import { RemoveTwoFactorAuthComponent } from './pages/settings/security/remove-two-factor-auth/remove-two-factor-auth.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { PartnerCategoryComponent } from './pages/partner/partner-category/partner-category.component';
import { PartnerCardComponent } from './pages/partner/partner-card/partner-card.component';
import { PartnerTestimonialComponent } from './pages/partner/partner-testimonial/partner-testimonial.component';
import { ShareMovementTrackerComponent } from './pages/mysec/mysec-settings/share-movement-tracker/share-movement-tracker.component';
import { PartnerCampaignCardComponent } from './pages/partner/partner-campaign-card/partner-campaign-card.component';
import { EmptyPartnerCampaignCardComponent } from './pages/partner/empty-partner-campaign-card/empty-partner-campaign-card.component';
import { ShariahCompliantInvestmentComponent } from './pages/shariah-compliant-investment/shariah-compliant-investment.component';
import { PstxTopUpComponent } from './pages/my-dashboard/pstx-top-up/pstx-top-up.component';
import { MoreActionsComponent } from './pages/my-dashboard/more-actions/more-actions.component';
import { MyDashboardComponent } from './pages/my-dashboard/my-dashboard.component';
import { InProgressComponent } from './pages/my-dashboard/in-progress/in-progress.component';
import { DashboardNotificationsComponent } from './pages/my-dashboard/dashboard-notifications/dashboard-notifications.component';
import { DashboardOrdersComponent } from './pages/my-dashboard/dashboard-orders/dashboard-orders.component';
import { DashboardDonutChartComponent } from './pages/my-dashboard/dashboard-donut-chart/dashboard-donut-chart.component';
import { UmInnovationsComponent } from './pages/landing/partners/um-innovations/um-innovations.component';

initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    MysecLayoutComponent,
    SignupComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RegisterEmailComponent,
    RegisterCorporateComponent,
    RegisterInvestorComponent,
    ProceedEKycComponent,
    RegisterIssuerComponent,
    ProfileComponent,
    SettingsComponent,
    VerifyEmailComponent,
    RaiseComponent,
    IssuerRepresentativeComponent,
    NewApplicationComponent,
    ShareholdersAndDirectorsComponent,
    LandingComponent,
    CampaignSectionComponent,
    CampaignInfoComponent,
    QaListComponent,
    InvestNowComponent,
    ConfirmInvestComponent,
    BusinessDirectoryComponent,
    EventsComponent,
    MyApplicationComponent,
    ProcessListComponent,
    OfferCreationComponent,
    EKycResultComponent,
    CampaignFormComponent,
    ApplicationFormComponent,
    CreateCampaignDetailsComponent,
    InvestmentsComponent,
    ChoosePaymentComponent,
    CompanyDetailsComponent,
    FpxPaymentComponent,
    PaymentReceiptComponent,
    CampaignInvestorListComponent,
    PaymentProcessComponent,
    MakePaymentComponent,
    RaiseFundReceiptComponent,
    EKycInProgressComponent,
    EddFormComponent,
    UploadDocumentComponent,
    FaqsComponent,
    RisksComponent,
    TncModalComponent,
    RiskWarningComponent,
    ForceUpdateMessageComponent,
    EquityCrowdfundingComponent,
    FeeStructureComponent,
    AboutPitchinComponent,
    BusinessInfoComponent,
    BusinessOverviewComponent,
    CampaignDraftComponent,
    MysecSettingsComponent,
    TeamManagementComponent,
    AddTeamMemberComponent,
    PortfolioSharePopupComponent,
    TickerCodeComponent,
    CampaignDraftRejectComponent,
    PrivacyPolicyComponent,
    WebsiteTermsConditionsComponent,
    ServiceTermsConditionsComponent,
    PstxTermsConditionsComponent,
    NomineeAgreementComponent,
    BeyondFourComponent,
    CampaignFiltersComponent,
    SuccessCampaignsComponent,
    LiveCampaignsComponent,
    WhyInvestComponent,
    LearnComponent,
    LearnArticleComponent,
    LearnPreviewComponent,
    LearnArticleSubmenuComponent,
    LearnArticleReadmoreComponent,
    LearnArticleFooterComponent,
    RayaWithPitchinComponent,
    MrantiComponent,
    PersonalDataProtectionComponent,
    IeoTermsConditionsComponent,
    MyCampaignComponent,
    SavedOffersComponent,
    EquityCampaignComponent,
    CampaignHeaderComponent,
    CampaignHeadingComponent,
    CampaignVideoComponent,
    CampaignStatisticsComponent,
    CampaignBadgeComponent,
    CampaignBusinessOverviewComponent,
    CampaignPerformanceComponent,
    CampaignHistoriesComponent,
    CampaignBusinessInfoComponent,
    CampaignInfoTabComponent,
    CampaignInvestmentDetailsComponent,
    CampaignFooterComponent,
    CampaignWhatsNewComponent,
    EcosystemComponent,
    AcademyComponent,
    StyleGuideComponent,
    AnnouncementBarComponent,
    LoginBehalfRedirectionComponent,
    MyPortfolioComponent,
    MyAnnouncementsComponent,
    MySharesComponent,
    HistoryComponent,
    CampaignNewsComponent,
    ServiceSectionComponent,
    StatsSectionComponent,
    HowItWorksComponent,
    PartnershipComponent,
    BuySellTutorialComponent,
    BuySellTutorialContentComponent,
    InnerTabComponent,
    InnerTabContentComponent,
    BrokuComponent,
    ForInvestorsComponent,
    ForFoundersComponent,
    SignupLayoutComponent,
    DuitnowPaymentComponent,
    SharesPopUpComponent,
    ShareholderInformationComponent,
    TestimonialComponent,
    InvestorDashboardComponent,
    TcfCampaignCardComponent,
    ApplicationProcessListComponent,
    ValuationBasisPopupComponent,
    MobileSelectMenuComponent,
    WomenInTechComponent,
    WatchTowerAndFriendsComponent,
    StatementsReportsComponent,
    StatementsComponent,
    ReportsComponent,
    EnableTwoFactorAuthComponent,
    BackupCodesComponent,
    TwoFactorAuthInputComponent,
    RemoveTwoFactorAuthComponent,
    PartnerComponent,
    PartnerCategoryComponent,
    PartnerCardComponent,
    PartnerTestimonialComponent,
    ShareMovementTrackerComponent,
    PartnerCampaignCardComponent,
    EmptyPartnerCampaignCardComponent,
    ShariahCompliantInvestmentComponent,
    PstxTopUpComponent,
    MoreActionsComponent,
    MyDashboardComponent,
    InProgressComponent,
    DashboardNotificationsComponent,
    DashboardOrdersComponent,
    DashboardDonutChartComponent,
    UmInnovationsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'pitchin-web' }),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    MatCheckboxModule,
    MatSelectModule,
    MatListModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTabsModule,
    MomentModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    NgChartsModule,
    MatProgressBarModule,
    SwiperModule,
    DragDropModule,
    MatTableModule,
    MatChipsModule,
    MatIconModule,
    ShareButtonModule,
    ShareIconsModule,
    NgxMatTimepickerModule,
    MatTooltipModule,
    InfiniteScrollModule,
    CKEditorModule,
    CoreModule,
    HammerModule,
    CKEditorModule,
    MatTooltipModule,
    InfiniteScrollModule,
    PortalModule,
    NgxMaskModule.forRoot(),
    DigitOnlyModule,
    BrowserAnimationsModule,
  ],
  providers: [
    NgbActiveModal,
    httpInterceptorProviders,
    AuthService,
    AccountService,
    CampaignService,
    TCFCampaignService,
    // LoggingService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: FULL_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'RM ' },
    { provide: ErrorHandler, useClass: ErrorService },
    AuthGuard,
    CompanyService,
    SettingService,
    PushNotificationService,
    BusinessDirectoryService,
    RaiseService,
    HomeService,
    InvestmentService,
    PortfolioService,
    PaymentService,
    RssFeedService,
    NotificationsService,
    CsvService,
    NumberSuffixPipe,
    FileValidationService,
    StatusCodeResponseService,
    PrismicService,
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ErrorService,
    CountriesService,
    BlockAtProductionGuard,
    BusinessesService,
    NumberSuffixPipe,
    WalletService,
    TeamsService,
    DecimalPipe,
    FileValidationService,
    NumberSuffixPipe,
    FileValidationService,
    StatusCodeResponseService,
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
