<div class="tw-relative">
  <div
    class="tw-pointer-events-none tw-relative tw-z-[2] tw-flex tw-items-center tw-justify-between tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-border-divider tw-bg-divider-light tw-px-5 tw-py-2 tw-shadow-md"
  >
    <div>
      <span class="tw-font-bold tw-text-black">
        <p-icon *ngIf="icon" [name]="icon" iconClass="tw-w-5 tw-h-5 tw-mr-2.5"></p-icon>
        <span *ngIf="selectedParent">
          {{ selectedParent?.nameForMobile ?? selectedParent?.name | translate }}
        </span>
        <p-icon
          name="chevron-right"
          iconClass="tw-w-4 tw-h-4 tw-mx-1"
          *ngIf="selectedParent"
        ></p-icon>
      </span>
      <span>{{ selected | translate }}</span>
    </div>

    <i class="fa fa-caret-down tw-text-base"></i>
  </div>

  <select
    class="tw-absolute tw-left-0 tw-top-0 tw-z-[1] tw-h-full tw-w-full focus-visible:tw-outline-none"
    (change)="handleChange($event.target.value)"
  >
    <ng-container *ngFor="let group of menu">
      <option
        *ngIf="!group?.submenus?.length"
        [value]="group | json"
        [selected]="group.name === selected && group.name === selectedParent?.name"
        [disabled]="group.name === selected && group.name === selectedParent?.name"
      >
        {{ group.name | translate }}
      </option>
      <optgroup [label]="group.name | translate" *ngIf="group?.submenus?.length">
        <ng-container *ngFor="let item of group?.submenus">
          <option
            *ngIf="!item.show || item.show(role)"
            [value]="item | json"
            [selected]="item.name === selected && group.name === selectedParent?.name"
            [disabled]="item.name === selected && group.name === selectedParent?.name"
          >
            {{ item.name | translate }}
          </option>
        </ng-container>
      </optgroup>
    </ng-container>
  </select>
</div>
