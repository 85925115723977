import { InvestmentFilterType } from './../../../core/enum/investment-filter-type.enum';
import { CampaignStatus } from './../../../core/enum/campaign-status.enum';
import { TaxIncentivesEligibility } from './../../../core/enum/tax-incentives-eligibility.enum';
import { Sector } from './../../../core/models/company/sector';

export class CampaignSectionFilter {
  currentPage: number;
  orderBy: InvestmentFilterType;
  landingPageAngelTaxIncentiveEligibility: TaxIncentivesEligibility;
  selectedSectors: Sector[];
  sectorIds: number | number[];
  filterString: string;
  partnerIds: number[];
  partnerSlugs: string[];
  fundingStage: number;

  constructor(
    public campaignStatus: CampaignStatus | CampaignStatus[] = CampaignStatus.Live,
    public take: number = 12,
  ) {
    this.currentPage = 1;
    this.take = take;
    this.campaignStatus = campaignStatus;
    this.orderBy = InvestmentFilterType.All;
    this.landingPageAngelTaxIncentiveEligibility = TaxIncentivesEligibility.All;
    this.selectedSectors = [];
    this.filterString = '';
  }
}
