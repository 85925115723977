<div
  class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-[url(src/assets/img/banner/um-innovations-banner-mobile.png)] tw-bg-cover tw-bg-no-repeat lg:tw-bg-[url(src/assets/img/banner/um-innovations-banner.png)]"
>
  <div
    class="tw-flex tw-w-full tw-max-w-[1280px] tw-flex-col tw-items-center tw-gap-4 tw-px-16 tw-py-10 lg:tw-flex-row lg:tw-items-center lg:tw-justify-center lg:tw-gap-16 lg:tw-py-28"
  >
    <img
      src="/assets/img/hero/um-innovations-logo.svg"
      alt="UM Innovations"
      class="tw-w-80 lg:tw-w-[28rem]"
    />
    <h1
      class="tw-m-0 tw-flex tw-max-w-80 tw-flex-col tw-text-2xl tw-font-semibold tw-tracking-wide tw-text-white lg:tw-max-w-[40rem] lg:tw-text-4xl"
    >
      TRANSFORMING IDEA <span class="tw-text-[2.5rem] lg:tw-text-6xl">INTO REALITY</span>
    </h1>
  </div>
  <div
    class="tw-relative tw-flex tw-w-screen tw-justify-center tw-border-t tw-border-white tw-shadow-[0px_4px_32px_0px_#00000029]"
  >
    <div
      class="tw-absolute tw-left-0 tw-z-0 tw-hidden tw-h-full tw-w-6/12 tw-bg-white lg:tw-flex"
    ></div>
    <div
      class="tw-z-10 tw-flex tw-w-full tw-max-w-[1280px] tw-flex-col tw-border-white lg:tw-flex-row lg:tw-justify-center"
    >
      <section
        class="tw-w-full tw-bg-white tw-p-1 lg:tw-flex lg:tw-flex-1 lg:tw-justify-end lg:tw-py-4"
      >
        <div class="tw-flex tw-flex-col tw-items-center tw-p-8 lg:tw-pl-[15px]">
          <div class="tw-max-w-[35rem] tw-text-[#1C4597] md:tw-max-w-[80rem] lg:tw-pl-0">
            <p
              class="tw-text-left tw-text-base tw-font-semibold tw-leading-normal tw-tracking-wide sm:tw-text-sm md:tw-text-lg"
            >
              UM Innovations Sdn Bhd and pitchIN are proud to announce a new partnership aimed at
              empowering startups stemming from Universiti Malaya’s research. 
            </p>
            <p
              class="tw-text-left tw-text-base tw-font-normal tw-leading-normal sm:tw-text-sm md:tw-text-lg"
            >
              This collaboration enables UMI spin-offs to raise funds through pitchIN’s Equity
              Crowdfunding (ECF) and Token Crowdfunding (TCF) platforms. By providing access to
              alternative financing, this partnership supports the growth and commercialization of
              innovative ventures within Universiti Malaya’s entrepreneurial ecosystem, driving
              impactful contributions to Malaysia’s economy.
            </p>
          </div>
        </div>
      </section>
      <section
        class="tw-flex tw-flex-col tw-items-center tw-border-t tw-border-t-[#1C4597] tw-bg-white tw-p-6 tw-text-[#1C4597] lg:tw-max-w-[20rem] lg:tw-items-start lg:tw-justify-start lg:tw-bg-transparent lg:tw-py-12 lg:tw-pl-14 lg:tw-text-white"
      >
        <p
          class="tw-mb-1 tw-text-center tw-text-base md:tw-text-lg lg:tw-text-left lg:tw-text-lg lg:tw-font-thin"
        >
          Ready to take your startup to the next level?
        </p>
        <p class="tw-text-xl tw-font-semibold tw-tracking-wide lg:tw-text-lg">Discover how.</p>
        <a
          class="tw-flex tw-w-full tw-cursor-pointer tw-justify-center tw-rounded-md tw-bg-[#1C4597] tw-p-4 tw-text-lg tw-font-semibold tw-text-white tw-shadow hover:tw-text-white lg:tw-mt-4 lg:tw-bg-[#F3EC25] lg:tw-px-4 lg:tw-py-2 lg:tw-text-[#0D2955]"
          href="https://forms.gle/eTKN1JznUckSSVU9A"
          target="_blank"
        >
          Book a session with us
        </a>
      </section>
    </div>
  </div>
</div>

<section class="section" *ngIf="totalCampaigns === null">
  <div class="container-fluid">
    <agmo-loading></agmo-loading>
  </div>
</section>

<!-- Section: Coming soon (when no campaign exist) -->
<section
  class="section tw-flex tw-min-h-96 tw-flex-col tw-items-center tw-justify-center"
  *ngIf="totalCampaigns !== null && totalCampaigns === 0"
>
  <h2 class="tw-text-sm tw-font-bold md:tw-text-lg">No available campaigns yet</h2>
  <div class="tw-text-sm md:tw-text-lg">All available campaigns will be shown here</div>
</section>

<!-- Section: Pre-Live -->
<ng-container *ngIf="list[CampaignStatus.PreLive].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#1C4597] md:tw-text-3xl">
        {{ 'common.preLiveCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.PreLive].campaigns"
        [params]="list[CampaignStatus.PreLive].params"
        [total]="list[CampaignStatus.PreLive].total"
        [isLoading]="list[CampaignStatus.PreLive].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.PreLive)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<!-- Section: Live -->
<section class="section" *ngIf="list[CampaignStatus.Live].total > 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#1C4597] md:tw-text-3xl">
      {{ 'common.liveCampaigns' | translate }}
    </h2>
    <app-campaign-list
      [campaigns]="list[CampaignStatus.Live].campaigns"
      [params]="list[CampaignStatus.Live].params"
      [total]="list[CampaignStatus.Live].total"
      [isLoading]="list[CampaignStatus.Live].isLoading"
      [noRecordText]="'common.comingSoon'"
      (getCampaignList)="getList($event, CampaignStatus.Live)"
    ></app-campaign-list>
  </div>
</section>

<!-- Section: Success -->
<ng-container *ngIf="list[CampaignStatus.Success].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5 tw-text-xl tw-text-[#1C4597] md:tw-text-3xl">
        {{ 'campaign.SuccessFundedTitle' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.Success].campaigns"
        [params]="list[CampaignStatus.Success].params"
        [total]="list[CampaignStatus.Success].total"
        [isLoading]="list[CampaignStatus.Success].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.Success)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<section
  class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-bg-[#0D2955] tw-p-8 md:tw-gap-8 md:tw-p-16"
>
  <h3 class="tw-text-xl tw-font-bold tw-tracking-wide tw-text-white md:tw-text-4xl">
    About UM Innovations
  </h3>
  <div class="tw-flex tw-max-w-[35rem] tw-flex-col md:tw-max-w-4xl">
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      UM Innovations Sdn. Bhd. (“UMI”) is a wholly owned subsidiary of UM Holdings Sdn. Bhd.
      (“UMH”), a wholly owned company of Universiti Malaya (“UM”). 
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      UMI’s primary objective is to commercialize R&D and innovation output of UM through the
      establishment of spin off companies and incubatees within UM ecosystem. Its role as a Venture
      Builder is to identify and develop business and investment opportunities to enable UM’s
      commercial venture’s growth and sustainability. 
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      Currently, there are 10 spin-off and 27 “business pods/incubatees“ offering various products
      and services to various industries including Engineering, Education, Medical Technology,
      Artificial Intelligent (AI), and Life Science. 
    </p>
    <p class="tw-text-left tw-text-base tw-text-white md:tw-text-lg">
      Our aim is to contribute to generating income for Universiti Malaya through a
      technopreneurship program, driving impactful initiatives that benefit local communities while
      scaling their influence to a global level. 
    </p>
  </div>

  <a
    href="https://umi.com.my/"
    target="_blank"
    class="tw-rounded-full tw-bg-[#F3EC25] tw-px-6 tw-py-4 tw-text-base tw-font-semibold tw-text-[#0D2955] md:tw-text-lg"
    >More on UM Innovations</a
  >
</section>
