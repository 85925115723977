import { Component, OnInit } from '@angular/core';
import { TabMenuItem } from '../../core/models/menu/tab-menu-item';
import { MenuService } from '../../core/services/menu.service';
import { environment } from '../../../environments/environment';
import { UserProfile } from '../../core/models/user/user-profile';
import { AccountService } from '../../core/services/api/account.service';
import { DefaultProfileImage } from '../../core/services/constant';
import { PortfolioService } from '../../core/services/api/portfolio.service';
import { filter, finalize } from 'rxjs';
import { PortfolioSummary } from '../../core/models/portfolio/portfolio-summary';
import { ErrorService } from '../../core/services/error.service';
import { Role } from '../../core/enum/role.enum';
import { StorageService } from '../../core/services/storage.service';
import { InvestorType } from '../../core/enum/investor-type.enum';
import { TCFCampaignService } from '../../core/services/api/tcf-campaign.service';
import { PstxAccountService } from '../../core/services/pstx-account.service';
import { Wallet } from '../../core/models/mysec/wallet';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-investor-dashboard',
  templateUrl: './investor-dashboard.component.html',
})
export class InvestorDashboardComponent implements OnInit {
  public environment = environment;
  ieoBaseUrl: string = environment.ieoBaseUrl;
  tabs: TabMenuItem[] = [];
  profile: UserProfile;
  selectedMenu: string;

  tokenBalance: number = 0;
  isTokenBalanceLoading: boolean = false;

  portfolioSummary: PortfolioSummary;
  isPortfolioSummaryCollapsed: boolean = true;
  isPortfolioSummaryLoading: boolean = true;
  role: Role;

  pstxWallet: Wallet;
  isPstxWalletFound: boolean = false;

  defaultProfileImage = DefaultProfileImage;

  Role: typeof Role = Role;
  InvestorType: typeof InvestorType = InvestorType;

  constructor(
    private menuService: MenuService,
    private accountService: AccountService,
    private portfolioService: PortfolioService,
    private errorService: ErrorService,
    private storageService: StorageService,
    private tcfCampaignService: TCFCampaignService,
    private pstxAccountService: PstxAccountService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.tabs = this.menuService.menu.investorDashboardMenu;

    this.role = this.storageService.role;

    this.accountService.currentUser.subscribe((profile) => {
      this.profile = profile;
    });

    this.pstxAccountService.getWallet().subscribe((wallet: Wallet) => {
      this.pstxWallet = wallet;
    });

    this.pstxAccountService.getIsWalletFound().subscribe((isFound: boolean) => {
      this.isPstxWalletFound = isFound;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.getActiveMenu();
    });

    this.getActiveMenu();
    this.getPortfolioSummary();
  }

  getActiveMenu() {
    const path = window?.location?.pathname;

    for (let item of this.tabs) {
      if (!item?.submenus?.length && item.url === path) {
        this.selectedMenu = item.name;
        return;
      }
    }

    this.selectedMenu = this.tabs[1].submenus.find((x) => path.startsWith(x.url))?.name;
  }

  handleMenuChanged(selectedTab: TabMenuItem) {
    if (selectedTab.isExternal) {
      window.location.href = selectedTab.url;
      return;
    }

    this.router.navigate([selectedTab.url], {
      relativeTo: this.route,
    });
  }

  togglePortfolioSummary() {
    this.isPortfolioSummaryCollapsed = !this.isPortfolioSummaryCollapsed;
  }

  getPortfolioSummary() {
    this.portfolioService
      .getPortfolio(1, 1)
      .pipe(
        finalize(() => {
          this.isPortfolioSummaryLoading = false;
        }),
      )
      .subscribe({
        next: (data) => {
          this.portfolioSummary = data.summary;
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  getTokenBalance() {
    this.tcfCampaignService
      .getTotalInvestments()
      .pipe(
        finalize(() => {
          this.isTokenBalanceLoading = false;
        }),
      )
      .subscribe({
        next: (data) => {
          this.tokenBalance = data;
        },
      });
  }
}
