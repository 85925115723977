<page
  name="dashboard.portfolio"
  description="settings.equityPortfolioDescription"
  headerType="h2"
  productBadge="ecf"
>
  <section [class.is-loading]="isLoading">
    <div class="tw-grid tw-gap-5 sm:tw-grid-cols-2">
      <!-- Total indicative value -->
      <div
        class="tw-flex tw-items-center tw-gap-2.5 tw-rounded-lg tw-bg-[radial-gradient(96.48%_131.02%_at_1.82%_0%,#667085_0%,#181A1F_100%)] tw-p-5 max-md:tw-flex-wrap"
      >
        <span
          class="overview-label !tw-text-xs tw-uppercase !tw-text-white/90 max-md:tw-flex-[0_0_100%] md:tw-max-w-[85px]"
        >
          {{ 'portfolio.totalMarketValue' | translate }}
          <button
            type="button"
            #tooltipTotalMarket="matTooltip"
            (click)="tooltipTotalMarket.toggle()"
            class="btn-transparent tw-align-top"
            matTooltip="{{ 'portfolio.totalMarketValueTooltip' | translate }}"
          >
            <div class="pitchin-icon pitchin-icon-help-circle-light p-help-icon--sm"></div>
          </button>
        </span>
        <div class="overview-value tw-flex-auto tw-text-right !tw-font-normal !tw-text-white">
          <div class="tw-flex tw-flex-wrap tw-gap-4 max-md:tw-justify-between md:tw-justify-end">
            <div class="max-md:tw-order-2">
              <div *ngIf="portfolioSummary?.deltaChanges" class="p-skeleton-hidden tw-text-xs">
                <span
                  [class.tw-text-secondary]="portfolioSummary.deltaChanges > 0"
                  [class.tw-text-primary]="portfolioSummary.deltaChanges < 0"
                >
                  <span
                    class="tw-rounded-full tw-px-1 tw-py-0.5 tw-font-medium"
                    [ngClass]="{
                      'tw-bg-primary-light': portfolioSummary.deltaChanges < 0,
                      'tw-bg-secondary-lightest': portfolioSummary.deltaChanges > 0,
                    }"
                  >
                    <span class="tw-mr-1.5">
                      <i
                        *ngIf="portfolioSummary.deltaChanges > 0"
                        class="fa fa-arrow-up"
                        aria-hidden="true"
                      ></i>
                      <i
                        *ngIf="portfolioSummary.deltaChanges < 0"
                        class="fa fa-arrow-down"
                        aria-hidden="true"
                      ></i>
                    </span>
                    {{ portfolioSummary.deltaChanges | number: '1.0-2' }}%
                  </span>
                </span>
                <span class="tw-mt-1 tw-block tw-text-white/60">
                  {{ portfolioSummary.amountChanges | numberSuffix: { numberFormat: '1.2-2' } }}
                </span>
              </div>
            </div>
            <span class="p-skeleton-bar tw-text-3xl">{{
              portfolioSummary?.totalMarketValue | numberSuffix: { numberFormat: '1.2-2' }
            }}</span>
          </div>
        </div>
      </div>

      <!-- Gross investment value -->
      <div
        class="tw-flex tw-items-center tw-gap-2.5 tw-rounded-lg tw-bg-[radial-gradient(97.44%_129.94%_at_1.42%_6.67%,#D00000_0%,#6A0000_100%)] tw-p-5 max-md:tw-flex-wrap"
      >
        <span
          class="overview-label !tw-text-xs tw-uppercase !tw-text-white/90 max-md:tw-flex-[0_0_100%] md:tw-max-w-[100px]"
        >
          {{ 'portfolio.grossInvestment' | translate }}

          <button
            type="button"
            #tooltipGross="matTooltip"
            (click)="tooltipGross.toggle()"
            class="btn-transparent tw-align-top"
            matTooltip="{{ 'portfolio.grossInvestmentTooltip' | translate }}"
          >
            <div class="pitchin-icon pitchin-icon-help-circle-light p-help-icon--sm"></div>
          </button>
        </span>
        <div class="overview-value tw-flex-auto !tw-font-normal !tw-text-white md:tw-text-right">
          <span class="p-skeleton-bar tw-text-3xl">{{
            portfolioSummary?.grossInvestment | numberSuffix: { numberFormat: '1.2-2' }
          }}</span>
        </div>
      </div>
    </div>
  </section>

  <div class="tw-mb-5 tw-mt-5">
    <ul class="p-tab">
      <li
        class="p-tab__item max-lg:tw-flex-1 max-lg:tw-text-center"
        [class.active]="selectedTab === 'portfolio'"
      >
        <a class="p-tab__link" [routerLink]="['/dashboard/portfolio']">{{
          'portfolio.holdings' | translate
        }}</a>
      </li>
      <li
        class="p-tab__item max-lg:tw-flex-1 max-lg:tw-text-center"
        [class.active]="selectedTab === 'history'"
      >
        <a
          class="p-tab__link"
          [routerLink]="['/dashboard/portfolio']"
          [queryParams]="{ selectedTab: 'history' }"
          >{{ 'portfolio.history' | translate }}</a
        >
      </li>
    </ul>
  </div>

  <app-my-shares
    *ngIf="selectedTab === 'portfolio'"
    [portfolioListModel]="portfolioListModel"
    [total]="total"
  ></app-my-shares>

  <app-history *ngIf="selectedTab === 'history'"> </app-history>

  <!-- Hide for Phase 2 -->
  <!-- <div>
        <div class="col-lg-8 mb-3">
            <div class="chart-panel">
                <div class="d-flex">
                    <div class="chart-title w-75">{{ 'portfolio.capitalDeploymentOverTime' | translate }}</div>
                    <div class="chart-filter">
                        <div class="input-field date-input">
                            <input matInput class="form-control" [matDatepicker]="selectedYearPicker"
                                [(ngModel)]="selectedYear">
                            <mat-datepicker-toggle matSuffix [for]="selectedYearPicker">
                                <div class="pitchin-icon pitchin-icon-calendar" matDatepickerToggleIcon></div>
                            </mat-datepicker-toggle>
                            <mat-datepicker #selectedYearPicker startView="multi-year"
                                (yearSelected)="chosenYearHandler($event, selectedYearPicker)"></mat-datepicker>
                        </div>
                    </div>
                </div>
                <canvas baseChart width="400" height="200" [datasets]="lineChartData" [labels]="lineChartLabels"
                    [options]="lineChartOptions" [colors]="lineChartColors" [legend]="false" chartType="line">
                </canvas>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="chart-panel">
                <div class="chart-title">{{ 'portfolio.investmentCategory' | translate }}</div>
                <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" chartType="pie" height="200" width="200"
                    [options]="pieChartOptions" [legend]="true" [colors]="pieChartColors">
                </canvas>
            </div>
        </div>
    </div> -->
</page>

<div class="container-fluid">
  <app-json-ld [itemLd]="portfolioFaqLd"></app-json-ld>
  <app-faq-accordion [accordionItems]="pageFaqs"></app-faq-accordion>

  <div class="tw-my-5">
    <div class="tw-mb-1 tw-font-bold">
      {{ 'common.disclaimer' | translate }}
    </div>
    <div class="tw-text-sm">
      {{ 'common.disclaimerContent' | translate }}
    </div>
  </div>
</div>
