import { Injectable } from '@angular/core';
import { CampaignFiltersMenu } from '../../../../core/models/campaign/campaign-filters-menu';

@Injectable({
  providedIn: 'root',
})
export class CampaignFiltersService {
  constructor() {}

  filtersMenus: CampaignFiltersMenu[] = [
    {
      key: 'selectedSectors',
      label: 'Sectors',
      isArray: true,
      isMultiSelect: true,
      children: [
        { key: 1, label: 'AdTech & Media' },
        { key: 8, label: 'Agriculture' },
        { key: 58, label: 'Consumer' },
        { key: 85, label: 'E-Commerce' },
        { key: 120, label: 'FinTech' },
        { key: 122, label: 'Food & Beverages' },
        { key: 141, label: 'Healthcare' },
        { key: 192, label: 'Manufacturing' },
        { key: 207, label: 'Mobile Application' },
        { key: 274, label: 'Services' },
        { key: 298, label: 'Technology' },
        { key: 248, label: 'PropTech' },
      ],
    },
    {
      key: 'investment',
      label: 'Investment',
      childrenGroups: [
        {
          key: 'valuation',
          label: 'Valuation',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: '< RM5,000,000' },
            { key: 2, label: '> RM5,000,000' },
            { key: 3, label: '> RM10,000,000' },
            { key: 4, label: '> RM20,000,000' },
            { key: 5, label: '> RM50,000,000' },
          ],
        },
        {
          key: 'minimumInvestment',
          label: 'Minimum Investment',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: '< RM5,000' },
            { key: 2, label: '> RM5,000' },
            { key: 3, label: '> RM10,000' },
          ],
        },
        {
          key: 'minimumTarget',
          label: 'Minimum Target',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: '< RM500,000' },
            { key: 2, label: '> RM500,000' },
            { key: 3, label: '> RM1,000,000' },
          ],
        },
        {
          key: 'landingPageAngelTaxIncentiveEligibility',
          label: 'Tax Eligibilities',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: 'ATI' },
            { key: 2, label: 'None' },
          ],
        },
      ],
    },
    {
      key: 'business',
      label: 'Business',
      childrenGroups: [
        {
          key: 'businessModel',
          label: 'Business Model',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: 'B2B' },
            { key: 2, label: 'B2C' },
            { key: 3, label: 'B2B2C' },
          ],
        },
        {
          key: 'digitalisation',
          label: 'Digitalisation',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: 'Digital' },
            { key: 2, label: 'Non-digital' },
            { key: 3, label: 'Mixed' },
          ],
        },
        {
          key: 'companyStage',
          label: 'Company Stage',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: 'Pre-revenue' },
            { key: 2, label: 'Growth' },
            { key: 3, label: 'Maturity' },
          ],
        },
        {
          key: 'fundingStage',
          label: 'Funding Stage',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: 'Pre-seed' },
            { key: 2, label: 'Seed' },
            { key: 3, label: 'Series A' },
            { key: 4, label: 'Series B & onwards' },
          ],
        },
      ],
    },
    {
      key: 'highlights',
      label: 'Highlights',
      childrenGroups: [
        {
          key: 'impact',
          label: 'Impact',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: 'ESG' },
            { key: 2, label: 'UN SDG' },
            { key: 3, label: 'Shariah compliant' },
            { key: 4, label: 'Women-led' },
          ],
        },
        {
          key: 'partnership',
          label: 'Partnership',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: 'Venture backed' },
            { key: 2, label: 'Accelerator backed' },
            { key: 3, label: 'R&D backed' },
            { key: 4, label: 'Industry partnership' },
          ],
        },
        {
          key: 'presence',
          label: 'Presence',
          isArray: false,
          isMultiSelect: false,
          children: [
            { key: 1, label: 'Local' },
            { key: 2, label: 'Regional' },
            { key: 3, label: 'Global' },
          ],
        },
      ],
    },
  ];
}
