import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PostMessageService {
  private window;

  constructor(
    private readonly _windowRef: WindowReferenceService,
    @Inject(PLATFORM_ID) private readonly platform: Object,
  ) {
    this.window = _windowRef.nativeWindow;
  }

  private postMessage(message: string | object, eventData?: string) {
    if (!isPlatformBrowser(this.platform)) return;

    this.window.addEventListener('message', (event) => {
      if (event.origin.includes(environment.ieoBaseUrl)) return;

      if (eventData && event.data === eventData) {
        event.source.postMessage(message, event.origin);
        return;
      }

      event.source.postMessage(message, event.origin);
    });
  }

  // TCF side will post `requestLocalStorage` message whenever there's a need to sync localStorage
  syncLocalStorage() {
    console.log('SDK auth - attempt to sync localStorage');

    this.postMessage(
      {
        type: 'localStorage',
        data: { ...localStorage },
      },
      'requestLocalStorage',
    );
  }
}
