import { Component, Input, OnInit } from '@angular/core';
import { CampaignInfo } from '../../../../core/models/campaign/campaign-info';
import {
  BusinessModel,
  CompanyStage,
  Digitalisation,
  FundingStage,
  Impact,
  PartnershipBacked,
  Presence,
} from '../../../../core/enum/campaign-categorisation.enum';

@Component({
  selector: 'app-issuer-campaign-tags',
  templateUrl: './issuer-campaign-tags.component.html',
  styleUrls: ['./issuer-campaign-tags.component.scss'],
})
export class IssuerCampaignTagsComponent implements OnInit {
  @Input() campaign: CampaignInfo;
  tags: string[] = [];

  singleTags = [
    { paramName: 'companyStage', enum: CompanyStage },
    { paramName: 'digitalisation', enum: Digitalisation },
    { paramName: 'fundingStage', enum: FundingStage },
    { paramName: 'presence', enum: Presence },
    { paramName: 'partnershipBacked', enum: PartnershipBacked },
  ];

  ngOnInit(): void {
    if (!this.campaign) {
      return;
    }

    if (this.campaign.campaignBusinessModels) {
      this.tags.push(
        ...this.campaign.campaignBusinessModels.map((el) => BusinessModel[el.businessModel]),
      );
    }

    for (let singleTag of this.singleTags) {
      let paramName = singleTag.paramName;
      if (this.campaign[paramName]) {
        this.tags.push(singleTag.enum[this.campaign[paramName]]);
      }
    }

    if (this.campaign.campaignImpacts) {
      this.tags.push(...this.campaign.campaignImpacts.map((el) => Impact[el.impact]));
    }
  }
}
