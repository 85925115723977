<article
  class="tw-mt-10 tw-flex tw-w-full tw-max-w-5xl tw-flex-col tw-items-center tw-rounded-lg tw-border tw-border-black tw-border-opacity-35 tw-p-4 md:tw-px-0"
>
  <div
    class="gap-4 tw-mb-[-1rem] tw-flex tw--translate-y-10 tw-flex-col tw-items-center tw-justify-center tw-bg-white tw-p-2"
  >
    <h3
      class="tw-m-0 tw--translate-y-1 tw-whitespace-pre-line tw-bg-gradient-to-r tw-from-primary tw-to-black tw-bg-clip-text tw-text-center tw-font-body tw-text-3xl tw-font-semibold tw-text-transparent md:tw--translate-y-4"
    >
      {{ 'partnership.category.' + PartnerCategory[category] | translate }}
    </h3>
    <p class="tw-mb-0 tw-hidden tw-max-w-lg tw-text-center tw-text-base tw-text-black md:tw-flex">
      {{ 'partnership.categoryDesc.' + PartnerCategory[category] | translate }}
    </p>
  </div>
  <section
    class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-divide-y tw-divide-gray-500 tw-divide-opacity-20"
  >
    <ng-container *ngFor="let partner of partners">
      <app-partner-card [partner]="partner" />
    </ng-container>
  </section>
</article>
