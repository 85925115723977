import { Injectable } from '@angular/core';
import { Role } from '../../enum/role.enum';
import { StorageService } from '../storage.service';
import { firstValueFrom } from 'rxjs';
import { VerifySessionSecretModel } from '../../models/account/verify-session-secret.model';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
  private readonly route = environment.apiServer + 'Api/v1/Users';

  constructor(
    private readonly http: HttpClient,
    private readonly storageService: StorageService,
  ) {}

  isAuthenticated = () => {
    return (
      this.storageService.tokenExpiry &&
      this.storageService.role &&
      [Role.IndividualInvestor, Role.CompanyRep, Role.IssuerRep].includes(this.storageService.role)
    );
  };

  verifySessionSecret = async (model: VerifySessionSecretModel): Promise<any> => {
    const params = { 'noToken': 'noToken' };

    return await firstValueFrom(this.http.post(this.route + '/verifySecret', model, { params }));
  };
}
